.crisp-navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.crisp-navbar .crisp-navbar-options {
  font-size: 0.9em;
}
.crisp-navbar-subheader {
  position: sticky;
  top: 44px;
  z-index: 10;
}
.crisp-navbar-subheader .subheader-right-menu .nav-link {
  margin-right: 0 !important;
}
.navbar-brand img {
  max-height: 30px;
  margin-right: 0.5rem;
}
.navbar-brand span.crisp {
  margin-left: 1rem;
  font-size: 0.7em;
  color: rgba(255, 255, 255, 0.55);
}
.navbar-brand span.crisp-mobile {
  font-size: 0.7em;
  color: rgba(255, 255, 255, 0.55);
}
.logo-container {
  z-index: 20;
  margin: 10px 0;
  max-height: 70px;
}
.wrapper-home,
.wrapper-background,
.wrapper-tools,
.wrapper-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
  padding-top: 3rem;
  font-size: 18px;
  line-height: 24px;
  color: #404649;
}
.wrapper-home img,
.wrapper-background img,
.wrapper-tools img,
.wrapper-data img {
  max-width: 100%;
}
.wrapper-home hr,
.wrapper-background hr,
.wrapper-tools hr,
.wrapper-data hr {
  border-color: #2A66FF;
}
.wrapper-home p,
.wrapper-background p,
.wrapper-tools p,
.wrapper-data p {
  max-width: 100%;
  margin: 1.5rem auto 1.5rem;
}
.wrapper-home p span,
.wrapper-background p span,
.wrapper-tools p span,
.wrapper-data p span {
  color: #2A66FF;
  font-weight: 700;
}
.wrapper-home p.short,
.wrapper-background p.short,
.wrapper-tools p.short,
.wrapper-data p.short {
  max-width: 80%;
}
.wrapper-home p.img-footer,
.wrapper-background p.img-footer,
.wrapper-tools p.img-footer,
.wrapper-data p.img-footer {
  font-size: 12px;
  color: #888;
}
.wrapper-home p.short-footer,
.wrapper-background p.short-footer,
.wrapper-tools p.short-footer,
.wrapper-data p.short-footer {
  max-width: 80%;
  font-size: 0.95rem;
}
.wrapper-home h3,
.wrapper-background h3,
.wrapper-tools h3,
.wrapper-data h3 {
  align-self: flex-start;
  border-top: 1px solid #2A66FF;
  color: #2A66FF;
  font-size: 28px;
  font-weight: 700;
  padding-top: 1rem;
  margin-top: 3rem;
  width: 100%;
}
.wrapper-home h3 svg,
.wrapper-background h3 svg,
.wrapper-tools h3 svg,
.wrapper-data h3 svg {
  padding-top: 7px;
  margin-right: 15px;
  margin-bottom: 10px;
}
.wrapper-home h5,
.wrapper-background h5,
.wrapper-tools h5,
.wrapper-data h5 {
  align-self: flex-start;
  color: #2A66FF;
  max-width: 50%;
}
.wrapper-home h5:first-of-type,
.wrapper-background h5:first-of-type,
.wrapper-tools h5:first-of-type,
.wrapper-data h5:first-of-type {
  margin-top: 1.5rem;
}
.wrapper-home h5:not(:first-of-type),
.wrapper-background h5:not(:first-of-type),
.wrapper-tools h5:not(:first-of-type),
.wrapper-data h5:not(:first-of-type) {
  margin-top: 4rem;
}
.wrapper-home p.video-description,
.wrapper-background p.video-description,
.wrapper-tools p.video-description,
.wrapper-data p.video-description {
  margin-top: 0;
}
.wrapper-home div.tool-points,
.wrapper-background div.tool-points,
.wrapper-tools div.tool-points,
.wrapper-data div.tool-points {
  display: flex;
  flex-direction: row;
  column-gap: 4rem;
  margin-top: 2rem;
}
.wrapper-home div.tool-points div,
.wrapper-background div.tool-points div,
.wrapper-tools div.tool-points div,
.wrapper-data div.tool-points div {
  /*display: flex;
      flex-direction: column;*/
}
.wrapper-home div.tool-points div svg,
.wrapper-background div.tool-points div svg,
.wrapper-tools div.tool-points div svg,
.wrapper-data div.tool-points div svg {
  margin-bottom: 15px;
}
.wrapper-home div.tool-points-centered,
.wrapper-background div.tool-points-centered,
.wrapper-tools div.tool-points-centered,
.wrapper-data div.tool-points-centered {
  display: flex;
  flex-direction: row;
  column-gap: 4rem;
  margin-top: 2rem;
  justify-content: center;
}
.wrapper-home div.tool-points-centered div,
.wrapper-background div.tool-points-centered div,
.wrapper-tools div.tool-points-centered div,
.wrapper-data div.tool-points-centered div {
  display: flex;
  flex-direction: column;
  width: 30%;
}
.wrapper-home .home-alert,
.wrapper-background .home-alert,
.wrapper-tools .home-alert,
.wrapper-data .home-alert {
  margin: 4rem 10%;
}
.wrapper-tools img {
  max-width: 700px;
}
.wrapper-tools p {
  margin-left: 0;
}
.wrapper-tools p.img-footer {
  margin: 1.5rem auto 1.5rem;
}
.wrapper-tools ul {
  width: 100%;
}
.wrapper-tools .tools-table {
  font-size: 0.8rem;
}
.wrapper-tools .tools-table input,
.wrapper-tools .tools-table select {
  font-size: 0.75rem;
  font-style: italic;
}
.wrapper-tools .tools-table label.filter-label span {
  display: none;
}
.wrapper-home div.tool-points div {
  display: flex;
  flex-direction: column;
}
.wrapper-home,
.wrapper-background,
.wrapper-tools,
.wrapper-home,
.wrapper-data {
  padding-bottom: 60px;
}
.wrapper-data .content {
  margin-top: 1.5rem;
  align-self: start;
  width: 100%;
}
.wrapper-data .content h5 {
  font-size: 24px;
  font-weight: 400;
}
.wrapper-data .content svg {
  margin-right: 5px;
}
.wrapper-data .content .data-profiles {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
.wrapper-data li {
  padding-bottom: 0.35rem;
}
.wrapper-data li svg {
  margin-left: 10px;
}
.carousel {
  height: 75vh;
  font-size: 1rem;
}
.carousel .carousel-inner {
  height: 100%;
}
.carousel .carousel-inner .carousel-item {
  height: 100%;
}
.carousel .carousel-inner .carousel-item .carousel-caption {
  top: 3rem;
}
.carousel .carousel-inner .carousel-item .carousel-caption li {
  margin-bottom: 2vh;
}
.carousel .carousel-inner img {
  max-width: 90%;
  margin-bottom: 2vh;
}
.facet-browser-init-alert {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 5rem;
}
.facet-browser p.info {
  color: slategray;
  margin: 1rem 0 1rem 0;
  padding: 0;
  font-size: 0.9rem;
}
.facet-browser p.info2 {
  color: slategray;
  margin: 2rem 0 0 0;
  padding: 0;
}
.facet-browser .impact-chain-facet-browser {
  display: grid;
  grid-template-columns: 32% 68%;
  column-gap: 2rem;
  justify-content: center;
  margin-left: 15px;
}
.facet-browser .impact-chain-facet-browser .facet-column h6 {
  margin-bottom: 1rem;
  border-bottom: 1px solid #dee2e6;
}
.facet-browser .impact-chain-facet-browser .facet-column .facet-bars:last-child {
  max-height: 40vh;
  overflow-y: auto;
}
.facet-browser .impact-chain-facet-browser .facet-column .facet-bar {
  cursor: pointer;
}
.facet-browser .impact-chain-facet-browser .facet-column .facet-bar text {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.9rem;
  letter-spacing: 0.02em;
  font-size: 0.8rem;
}
.facet-browser .impact-chain-facet-browser .facet-column .facet-bar text.key {
  fill: #111;
}
.facet-browser .impact-chain-facet-browser .facet-column .facet-bar text.value {
  fill: #444;
  text-anchor: end;
}
.facet-browser .impact-chain-facet-browser .facet-column .facet-bar line {
  stroke: rgba(25, 42, 86, 0.25);
  stroke-width: 1;
}
.facet-browser .impact-chain-facet-browser .facet-column .facet-bar:hover {
  opacity: 0.7;
}
.facet-browser .impact-chain-facet-browser .facet-column .facet-bar svg text.selected {
  font-weight: bold;
  text-shadow: 1px 1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, -1px -1px 0 #fff;
}
.facet-browser .filter-list-row {
  min-height: 100px;
  display: flex;
  justify-content: center;
}
.facet-browser .filter-list-row .filter-category {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  align-items: center;
}
.facet-browser .filter-list-row .filter-category .list-group {
  margin-bottom: 2px;
}
.facet-browser .filter-list-row .filter-category .list-group .filter-tag {
  font-size: 0.7rem;
  padding: 0.3rem 0.5rem;
}
.facet-browser .filter-list-row .filter-category .filter-tag {
  font-size: 0.8rem;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
}
.facet-browser .filter-list-row .filter-category .filter-tag svg {
  cursor: pointer;
}
.facet-browser .filter-list-row .filter-category .filter-tag svg:hover {
  fill: #6c757d;
}
.facet-browser .btn-view-impact-chains-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}
.facet-browser .btn-view-impact-chains-row .btn-view-impact-chains {
  width: fit-content;
}
.modal-impact-chain-selector {
  font-size: 0.9rem;
}
.modal-impact-chain-selector .modal-body > div {
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
}
.modal-impact-chain-selector .modal-body > div > div {
  width: 100%;
}
.modal-impact-chain-selector p,
.modal-impact-chain-selector .list-group {
  margin-left: 25px;
}
.modal-impact-chain-selector p {
  color: #767676;
}
.modal-impact-chain-selector p.instruction {
  color: #767676;
  font-size: 0.8rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.modal-impact-chain-selector p.instruction span {
  font-style: italic;
}
.modal-impact-chain-selector .list-group {
  width: 75%;
}
.modal-impact-chain-selector .list-group .list-group-item {
  padding: 5px 10px 0.5rem;
}
.modal-impact-chain-selector .list-group .factor-initial-selector-item {
  display: flex;
  flex-direction: row;
}
.modal-impact-chain-selector .list-group .factor-initial-selector-item svg {
  margin: 3px 6px 0 20px;
}
.modal-impact-chain-selector .list-group .ic-initial-selector-item {
  display: flex;
  flex-direction: row;
}
.modal-impact-chain-selector .list-group .ic-initial-selector-item input {
  margin: 3px 6px 0 0px;
}
.ic-model-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  position: absolute;
  max-width: 750px;
  top: 135px;
  right: 340px;
  border: 1px solid lightgrey;
  padding: 6px 10px;
  font-size: 0.9rem;
}
.ic-model-name input {
  margin-right: 5px;
}
.ic-model-name .toast {
  border-radius: 0;
  box-shadow: none;
  width: 750px;
}
.ic-model-name .toast .toast-header {
  padding: 0.3rem 0.75rem;
}
.ic-model-name .toast .toast-header span {
  padding-left: 5px;
}
.ic-model-name .toast .toast-header small {
  margin-left: 10px;
}
.ic-model-name > strong {
  max-width: 37%;
  border-right: 1px solid lightgrey;
  text-align: right;
  padding-right: 20px;
  font-weight: 500px;
  font-size: 14px;
}
.ic-model-name > button,
.ic-model-name > small,
.ic-model-name > div {
  flex-grow: 1;
  font-size: 12px;
  text-align: left;
}
.hovered-link-toast {
  position: absolute;
  bottom: 0;
  transform: translate(15px, -20px);
}
.hovered-link-toast .toast {
  min-width: 650px !important;
  font-size: 0.8rem !important;
}
.hovered-link-toast .toast .badge {
  margin-right: 5px;
}
.network {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: row;
}
.network .svg-wrapper {
  width: calc(100% - 20rem - 1rem);
  height: 100%;
}
.network .svg-wrapper svg .node {
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
}
.network .svg-wrapper svg .node text {
  fill: #555;
  text-shadow: 1px 1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, -1px -1px 0 #fff;
}
.network .svg-wrapper svg .node text.selected {
  font-weight: bold;
  fill: #2A66FF;
  letter-spacing: 0.025rem;
}
.network .svg-wrapper svg .node text:hover {
  cursor: pointer;
  fill: crimson;
}
.network .svg-wrapper svg .node:hover {
  cursor: pointer;
}
.network .svg-wrapper svg .node.highlighted {
  fill: crimson;
  color: crimson;
}
.network .svg-wrapper svg .node.highlighted rect.bbox {
  opacity: 0.5;
  stroke-width: 1.5px;
  fill: crimson;
  fill-opacity: 0.2;
}
.network .svg-wrapper svg .node.highlighted text {
  fill: crimson;
  font-size: 13px;
}
.network .svg-wrapper svg .node rect.bbox {
  stroke: crimson;
  stroke-width: 0.5px;
  fill: none;
  opacity: 0;
}
.network .svg-wrapper svg .node .menubox {
  visibility: hidden;
}
.network .svg-wrapper svg .node .menubox path {
  fill: #0066ff;
}
.network .svg-wrapper svg .node .menubox rect {
  fill: white;
  opacity: 0.01;
  stroke: none;
}
.network .svg-wrapper svg .link {
  cursor: pointer;
  stroke-width: 1.5px;
  stroke: lightgrey;
  stroke-opacity: 0.65;
}
.network .svg-wrapper svg .link:hover {
  stroke: crimson;
}
.network .svg-wrapper svg .link.selected {
  stroke-width: 1.5px;
  stroke: black;
}
.network .svg-wrapper svg .link-label-container {
  pointer-events: none;
}
.network .svg-wrapper svg .link-label {
  fill: #777;
  letter-spacing: 0.05rem;
  font-size: 9px;
  font-family: Arial, Helvetica, sans-serif;
  text-shadow: 1px 1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, -1px -1px 0 #fff;
}
.network .svg-wrapper svg .group {
  stroke: #c0c0c0;
  stroke-dasharray: 1,3;
  fill: none;
}
.network .network-legend {
  border-left: 1px solid #ececec;
  top: 124px;
  right: 0;
  width: calc(20rem + 1rem);
  height: calc(100vh - 124px);
  min-height: 200px;
  overflow-y: auto;
}
.network .network-legend > div {
  position: relative;
  width: 100%;
  height: 100%;
}
.network .network-legend > div .panel {
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* panel containing info on hovered factor / link */
}
.network .network-legend > div .panel:nth-child(2) {
  overflow-y: auto;
  padding: 1rem;
  z-index: 2;
  background-color: rgba(248, 249, 250, 0.93);
  margin-top: 5px;
  border-top: 1px solid lightgrey;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}
.network .network-legend > div .panel:nth-child(2) p.name {
  font-weight: bold;
}
.network .network-legend > div .panel:nth-child(2) p.contextual-description {
  font-size: 0.85rem;
}
.network .network-legend > div .panel:nth-child(2) p span {
  margin: 0 5px;
}
.network .network-legend > div .panel:nth-child(2) ul,
.network .network-legend > div .panel:nth-child(2) li {
  font-size: 0.85rem;
}
.network .network-legend div.info {
  color: slategray;
  padding: 0rem 1rem;
  margin: 0;
  font-style: italic;
  font-size: 0.85rem;
}
.network .network-legend p.hoverable:hover {
  cursor: pointer;
  color: crimson;
}
.network .network-legend .input-group {
  padding: 0 1rem;
}
.network .network-legend svg text {
  pointer-events: all;
  font-size: 12px;
  cursor: pointer;
}
.network .network-legend svg text.enabled:hover {
  fill: crimson;
}
.network .network-legend svg text.enabled.highlighted {
  fill: crimson;
}
.network .network-legend svg text.factor {
  font-weight: bold;
}
.network .network-legend svg text.subfactor {
  font-size: 11px;
}
.offcanvas .offcanvas-header {
  background: beige;
  color: #664d03;
}
.offcanvas .offcanvas-header .offcanvas-title {
  font-size: 14px;
  font-weight: 300;
}
.offcanvas .offcanvas-header .offcanvas-title span.factor {
  text-transform: uppercase;
  font-weight: bold;
}
.offcanvas .offcanvas-body {
  padding: 0;
}
.offcanvas .offcanvas-body .factor-type-block {
  margin-bottom: 2rem;
}
.offcanvas .offcanvas-body .factor-type-block h6 {
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 14px;
  text-transform: uppercase;
  background-color: #e0e0e0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.offcanvas .offcanvas-body .factor-type-block h6 svg {
  margin-right: 0.5rem;
}
.offcanvas .offcanvas-body .factor-type-block div.factor {
  margin: 1rem 1rem 2rem 2rem;
  padding-bottom: 0.5rem;
  font-weight: 300;
  border-bottom: 1px solid #e0e0e0;
}
.offcanvas .offcanvas-body .factor-type-block div.factor div {
  padding-bottom: 0.25rem;
}
.offcanvas .offcanvas-body .factor-type-block div.factor .factor-name {
  font-weight: 500;
}
.offcanvas .offcanvas-body .factor-type-block div.factor .factor-name svg {
  margin-left: 0.5rem;
  margin-bottom: 0.25rem;
}
.offcanvas .offcanvas-body .factor-type-block div.factor .factor-name p {
  color: grey;
  font-style: italic;
  font-weight: 300;
  font-size: 0.8rem;
  margin-bottom: 0;
}
.offcanvas .offcanvas-body .factor-type-block div.factor .factor-description {
  margin-left: 0.75rem;
  font-size: 0.8rem;
}
.offcanvas .offcanvas-body .factor-type-block div.factor .factor-resources {
  margin-left: 0.75rem;
  font-size: 0.8rem;
  font-style: italic;
  color: #6c757d;
}
.navbar-sub-header .nav-link {
  margin-right: 3rem;
  font-size: 1rem;
}
.navbar-sub-header .nav-link.active {
  border: 1px solid grey;
}
.table-view {
  padding: 1rem 20px 60px 20px;
}
.table-view .tab-factors {
  margin-top: 1rem;
  border-bottom: 3px solid #555;
}
.table-view .tab-factors .tab .factor-type {
  float: left;
}
.table-view .tab-factors .tab .badge {
  float: left;
  font-weight: 300;
  color: grey;
}
.table-view .tab-factors button {
  font-weight: 600;
  letter-spacing: 0.03rem;
  color: black;
}
.table-view .tab-factors button.nav-link {
  border-radius: 0;
  background-color: rgba(248, 249, 250);
}
.table-view .tab-factors button.nav-link.active {
  background-color: #f0f0f0;
}
.table-view .search-wrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
}
.table-view .search-wrapper .form-control,
.table-view .search-wrapper button {
  font-size: 0.8rem;
}
.table-view .search-wrapper input[id="onlySelectedFactors"],
.table-view .search-wrapper input[id="resetSelectedFactors"] {
  margin-right: 5px;
}
.table-view .search-wrapper label {
  font-size: 12px;
}
.table-view .search-wrapper .search-wrapper-controls {
  border-right: 1px solid lightgray;
}
.table-view table {
  margin-top: 2rem;
  font-size: 0.8rem;
}
.table-view table thead {
  border-bottom: 2px solid #555;
}
.table-view table thead .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.table-view table thead .dropup .caret,
.table-view table thead .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.table-view table thead th.selection-cell-header {
  visibility: hidden !important;
}
.table-view table span.link-related-factor {
  font-style: italic;
  color: #6c757d;
  font-size: 0.75rem;
}
.modal .modal-header {
  background: beige;
  color: #664d03;
}
.popover {
  max-width: 600px !important;
}
.popover-body {
  font-size: 0.85rem;
  color: #444;
}
.modal-about,
.modal-FAQ,
.modal-imprint {
  font-size: 0.9rem;
}
.modal-about .modal-body,
.modal-FAQ .modal-body,
.modal-imprint .modal-body {
  padding: 2rem 3rem;
  max-height: 60vh;
  overflow-y: scroll;
}
.modal-about p,
.modal-FAQ p,
.modal-imprint p,
.modal-about ul,
.modal-FAQ ul,
.modal-imprint ul {
  color: #767676;
}
.modal-about p.instruction,
.modal-FAQ p.instruction,
.modal-imprint p.instruction {
  color: #767676;
  font-size: 0.8rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.modal-about p.instruction span,
.modal-FAQ p.instruction span,
.modal-imprint p.instruction span {
  font-style: italic;
}
.modal-about h3,
.modal-FAQ h3,
.modal-imprint h3 {
  align-self: flex-start;
  color: #2A66FF;
  font-size: 28px;
  font-weight: 500;
  padding-top: 1rem;
  margin-top: 1rem;
  width: 100%;
}
.modal-about h5,
.modal-FAQ h5,
.modal-imprint h5 {
  align-self: flex-start;
  font-size: 16px;
  color: #2A66FF;
  font-weight: 500;
  padding-top: 1rem;
  margin-top: 1rem;
  width: 100%;
}
.modal-about .q,
.modal-FAQ .q,
.modal-imprint .q {
  color: #2A66FF;
  font-weight: 500;
  margin-top: 1.25rem;
  margin-bottom: 0.25rem;
}
.modal-about .imprint-logos,
.modal-FAQ .imprint-logos,
.modal-imprint .imprint-logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.modal-about .imprint-logos img,
.modal-FAQ .imprint-logos img,
.modal-imprint .imprint-logos img {
  display: block;
  max-height: 200px;
  width: auto;
}
.modal-about .imprint-logos img.cgiar,
.modal-FAQ .imprint-logos img.cgiar,
.modal-imprint .imprint-logos img.cgiar {
  max-height: 100px;
  margin-top: 45px;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: white;
  border-top: 1px solid #d0d0d0;
  padding: 5px 0;
}
.footer img {
  cursor: pointer;
  max-height: calc(60px - (5px*2));
  vertical-align: top;
  margin: 0 20px;
}
.userguide-icon {
  padding: 0 5px;
}
.userguide-icon:hover {
  cursor: pointer;
  color: crimson;
}
